.accessibility-enabled {
	.title-circle {
		&--white {
			&:after {
				display: none;
			}
		}
	}

	.icon-linkedin--white {
		&:after {
			background-image: url($img-path + "icon-linkedin--black.svg");
		}
	}

	.icon-email--white {
		&:after {
			background-image: url($img-path + "icon-mail--black.svg");
		}
	}

	.icon-arrow--white {
		&:after {
			background-image: url($img-path + "icon-arrow-right--black.svg");
		}
	}

	.map {
		&__background {
			path {
				fill: $black;
			}

			img{
				display: block;
			}
		}
	}

	.landing__bottom {
		.cosmetic-circles {
			display: none;
		}
	}

	.circle--purple {
		background-image: url($img-path + "circle-black.svg");
	}

	.avantages__item {
		&:nth-child(1) {
			.card-plain__icon {
				background-image: url($img-path + "icon-colors-leaflet--accessible.svg");
			}
		}
		&:nth-child(2) {
			.card-plain__icon {
				background-image: url($img-path + "icon-certified--accessible.svg");
			}
		}
		&:nth-child(3) {
			.card-plain__icon {
				background-image: url($img-path + "icon-progressive--accessible.svg");
			}
		}
	}

	.objectifs:before {
		display: none;
	}

	.video-section:after {
		display: none;
	}

	.news:after {
		display: none;
	}

	.modal__dialog:after {
		display: none;
	}

	.button-close {
		background-image: url($img-path + "icon-close-orange--accessible.svg");
	}
}

/* Hide content */

.visually-hidden {
	border: 0 !important;
	clip: rect(0 0 0 0);
	height: 1px !important;
	margin: -1px !important;
	overflow: hidden !important;
	padding: 0 !important;
	position: absolute !important;
	width: 1px !important;
}
