// Reset lists
@mixin reset-ul {
	margin: 0;
	padding: 0;
	list-style: none;

	& > li {
		position: static;
		padding: 0;
		margin: 0;

		&::before {
			content: "";
			display: none;
		}
	}

	li > ul,
	li > ol {
		margin: 0;
		padding: 0;
	}
}
