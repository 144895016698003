.swiper-button {
	display: block;
	width: 22px;
	height: 38px;
	background-color: transparent;
	border: 0;
	cursor: pointer;
	background-size: contain;
	background-repeat: no-repeat;
	background-position: center center;
	opacity: 1;
	visibility: visible;
	transition-property: opacity, visibility;
	transition-duration: 100ms;
	transition-timing-function: ease-in-out;
	&-prev {
		background-image: url($img-path + "icon-chevron-orange--left.svg");
	}
	&-next {
		background-image: url($img-path + "icon-chevron-orange--right.svg");
	}

	&-disabled {
		opacity: 0;
		visibility: hidden;
	}
}

.swiper-pagination {
	display: flex;
	justify-content: center;
	align-items: center;
	box-sizing: border-box;

	&-bullet {
		display: block;
		width: 16px;
		height: 16px;
		padding: 0;
		font-size: 0;
		margin: 0 4px;
		border-radius: 50%;
		border: 1px solid $white;
		background-color: transparent;
		cursor: pointer;

		&-active {
			background-color: $white;
		}
	}

	&--orange {
		.swiper-pagination-bullet {
			border-color: var(--color-primary);
			background-color: $white;

			&-active {
				background-color: var(--color-primary);
			}
		}
	}
}
