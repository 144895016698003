// header sizes
.navbar--full {
	padding-top: 28px;

	.site-header {
		&__content {
			grid-template-columns: 104px auto;
			align-items: flex-start;

			@include media-breakpoint-up(md) {
				grid-template-columns: 152.5px auto;
			}
		}

		&__nav {
			margin-top: 2px;
		}
	}
}

.navbar--compact {
	background-color: $white;
}

// Sticky header states
body {
	&:not(.has-sticky-header) {
		.site-header {
			@extend .navbar--full;
		}
	}

	&.has-sticky-header {
		.site-header {
			@extend .navbar--compact;
			position: fixed;
			top: 0;
			left: 0;
			z-index: 998;
			animation: 500ms ease-out slideInDown;
		}
	}
}

.main-menu {
	@include reset-ul;

	li {
		display: flex;
		align-items: center;
		margin: 0 10px;

		&:last-child {
			margin-right: 0;
		}
	}

	.menu-item {
		display: block;
		padding: 10px 0;
		@extend .text-sm;
		color: var(--color-primary);
		text-transform: uppercase;
		text-decoration: none;

		&:hover,
		&:focus {
			color: var(--color-secondary);
		}
	}
}

// header css
.site-header {
	padding: 6.765px 0;
	position: absolute;
	top: 0;
	left: 0;
	z-index: 99;
	width: 100%;

	@include media-breakpoint-up(md) {
		padding: 10px 0;
	}

	&__content {
		display: grid;
		grid-template-columns: 36.5px auto;
		justify-content: space-between;
		align-items: center;

		@include media-breakpoint-up(md) {
			grid-template-columns: 58.5px auto;
		}
	}

	&__branding {
		svg {
			display: block;
			max-width: 100%;
			height: auto;

			path {
				fill: var(--color-primary);
			}
		}
	}

	&__nav {
		display: flex;
		justify-content: flex-end;

		&__button {
			@include media-breakpoint-up(lg) {
				display: none;
			}
		}

		.main-menu {
			display: none;

			@include media-breakpoint-up(lg) {
				display: flex;
			}
		}
	}
}

// menu mobile
.mobile-menu {
	@extend .bg-concrete;
	width: 100%;
	height: 100%;
	position: fixed;
	top: 0;
	left: 0;
	transform: translateY(-101%);
	z-index: 999;
	background-color: $white;
	transition-property: transform, visibility;
	transition-duration: 0.2s;
	transition-timing-function: ease-in-out;
	visibility: hidden;

	@include media-breakpoint-up(lg) {
		display: none;
	}

	&.is-open {
		transform: translateY(0);
		visibility: visible;
	}

	&__header {
		padding: 20px 0;
		display: grid;
		grid-template-columns: 104px auto;
		align-items: flex-start;
		justify-content: space-between;

		&__button {
			margin-top: 10px;
		}
	}

	.main-menu {
		display: flex;
		flex-direction: column;
		align-items: center;
		max-width: 270px;
		margin: 0 auto;

		li {
			display: flex;
			justify-content: center;
			width: 100%;
			margin: 0 0 9px;

			&.main-menu__linkedin {
				margin-top: 7px;
			}

			.menu-item {
				width: 100%;
				text-align: center;
				border-bottom: 1px solid var(--color-secondary);
			}
		}
	}

	&__content {
		display: flex;
		flex-direction: column;
		justify-content: flex-start;
		align-items: center;
		height: calc(100vh - 144px);
		overflow-y: auto;
		padding: 25px 0 70px;

		.main-menu {
			margin-bottom: 30px;
		}

		.menu-legals {
			display: block;
			margin-top: auto;

			li {
				text-align: center;
				margin: 2px 0;
			}

			a {
				color: var(--color-primary);
			}
		}
	}
}
