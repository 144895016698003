@import url("https://fonts.googleapis.com/css2?family=Barlow+Semi+Condensed:wght@700&display=swap");
// stylelint-disable value-keyword-case
$font__main: "Trebuchet MS", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue",
	sans-serif;
$font__alt: "Barlow Semi Condensed", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue",
	sans-serif;
// stylelint-enable value-keyword-case
$font__code: monaco, consolas, "Andale Mono", "DejaVu Sans Mono", monospace;
$font__pre: "Courier 10 Pitch", courier, monospace;
$font__line-height-body: 1.5;
$font__line-height-pre: 1.5;
