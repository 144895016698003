.modal {
	// display: flex;
	justify-content: center;
	align-items: center;
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 999;
	overflow: hidden;
	background-color: rgba($white, 50%);
	display: none;

	&.is-active {
		display: flex;
	}

	&#entreprises-labellisees &{


		&__dialog{
			@include media-breakpoint-up(md) {
				padding: 57px 24px 50px 100px;
			}	
		}

		&__content{

			max-width: none;
				/* ===== Scrollbar CSS ===== */
			/* Firefox */
			scrollbar-width: auto;
			scrollbar-color: #ffffff #e7e6f3;

			/* Chrome, Edge, and Safari */
			&::-webkit-scrollbar {
				width: 12px;
			}

			&::-webkit-scrollbar-track {
				background: #e7e6f3;
				border-radius: 16px;
			}

			&::-webkit-scrollbar-thumb {
				background-color: #ffffff;
				border-radius: 16px;
				border: 2px solid #e7e6f3;
			}

			@include media-breakpoint-up(md) {
				// padding-bottom: 0;
			}	

			@include media-breakpoint-up(lg) {
				// overflow-y: unset;
			}
		}

	}

	&__dialog {
		position: relative;
		width: 95%;
		height: 95%;
		background-color: $white;
		border-radius: 24px;
		box-shadow: 0 0 40px rgba($black, 15%);
		padding: 62px 7px 40px 19px;
		overflow: hidden;

		@include media-breakpoint-up(md) {
			padding: 57px 24px 57px 100px;
		}

		&:after {
			content: "";
			@extend .circles--colored;
			width: 275.14px;
			height: 310px;
			position: absolute;
			bottom: -200px;
			left: -164px;
			z-index: 0;
			transform: rotate(90deg) scaleY(-1);
			opacity: 0.25;

			@include media-breakpoint-up(sm) {
				bottom: -196px;
				left: -109px;
			}

			@include media-breakpoint-up(md) {
				bottom: -108px;
				left: -107px;
			}

			@include media-breakpoint-up(lg) {
				left: -107px;
				bottom: -34px;
			}
		}

		&__wrapper {
			height: 100%;
			overflow-y: auto;

			@include media-breakpoint-up(lg) {
				overflow-y: hidden;
			}
		}
	}

	&.has-image {
		.modal__dialog__wrapper {
			display: grid;

			@include media-breakpoint-up(lg) {
				display: flex;
				flex-direction: row;
			}
		}

		.modal__content {
			margin-left: 0;
			margin-right: 0;
		}
	}

	&.title-centered {
		.modal__content__header {
			.title {
				text-align: center;
			}
		}
	}

	&__aside {
		order: 2;
		padding-bottom: 42px;

		@include media-breakpoint-up(lg) {
			order: unset;
			padding-top: 1.5%;
			padding-bottom: 0;
			margin-right: 7%;
		}

		&__image {
			position: relative;
			margin: 46px auto 0;

			@include media-breakpoint-up(lg) {
				width: 280px;
				margin: 0;
			}

			&:after {
				content: "";
				@extend .circle--purple;
				position: absolute;
				top: 2px;
				left: 50%;
				transform: translateX(calc(-50% + -37.5vw / 3));
				z-index: 0;

				@include media-breakpoint-up(sm) {
					width: 50px;
					height: 50px;
				}

				@include media-breakpoint-up(lg) {
					width: 80px;
					height: 80px;
					top: 2px;
					left: 1px;
					transform: none;
				}
			}

			img {
				min-width: 120px;
				width: 37.5vw;
				position: relative;
				z-index: 1;
				display: block;
				max-width: 280px;
				height: auto;
				border-radius: 50%;
				margin: 0 auto;

				@include media-breakpoint-up(md) {
					width: auto;
					max-width: 100%;
				}
			}

			figcaption {
				display: flex;
				flex-direction: column;
				align-items: center;
				text-align: center;
				@extend .h5;
			}

			.title {
				@extend .text-purple;
				margin-bottom: 2px;
			}

			.subtitle {
				color: $purple500;
				margin: 0;
			}
		}
	}

	&__content {
		position: relative;
		z-index: 1;
		max-width: 840px;
		padding: 10px 5px 10px 0;
		margin: 0 auto;

		@include media-breakpoint-up(md) {
			padding: 10px 76px 10px 0;
		}

		@include media-breakpoint-up(lg) {
			height: 100%;
			overflow-y: auto;
		}

		&__header {
			margin: 0 0 16px;
		}

		.pretitle {
			@extend .text-xs;
			color: $grey300;
			display: block;
			margin: 0;

			& + .title {
				margin: 8px 0 0;
			}
		}

		.title {
			@extend .h1;
			@extend .text-purple;
			margin: 0;

			& + .subtitle {
				margin: 12px 0 0;

				#nl-ok &{
					text-align: center;
				}
			}
		}

		.subtitle {
			@extend .em;
			color: $purple500;
			margin: 0;
		}

		&__body {
			color: $purple500;
		}
	}

	&__button-close {
		position: absolute;
		top: 11.3px;
		right: 11.3px;
		z-index: 2;
		@extend .button-close;
	}
}