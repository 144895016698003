.table{

    border-collapse: collapse;
    width: 100%;

    thead{

        tr{

            th{
                color: $purple300;
                text-align: left;
                border-top: 1px solid $purple300;
                border-bottom: 1px solid $purple300;
                text-transform: uppercase;
                padding: 10px;
            }
        }
    }

    tbody{

        tr{
            background-color: $white;

            &:nth-child(odd) {
                background-color: #F6F6F6;
            }

            td{

                color: $grey500;
                padding: 10px;

                // $purple500 bold pour les premières colonnes
                &:first-child{
                    font-weight: bold;
                    color: $purple500;
                }

                // Balises <a> dans les cellules couleur $orange300
                a{
                    color: $orange300;
                }
            }
        }

    }

    // Responsive
    &.bt{

        tr{
            display: block;
            padding: 10px 10px;
        }

        .bt-content{
            padding-left: 10px;
        }
    }
}