.landing {
	width: 100%;
	margin-top: 5px;
	overflow-x: hidden;

	&__top {
		@extend .bg-concrete;
		padding-top: 154px;

		@include media-breakpoint-up(md) {
			padding-top: 206px;
		}

		@include media-breakpoint-up(lg) {
			padding-top: 0;
		}

		&__content {
			position: relative;

			@include media-breakpoint-up(lg) {
				display: grid;
				grid-template-columns: repeat(2, 50%);
				justify-content: center;
				align-items: center;
			}
		}

		&__gradient {
			@extend .gradient-fluid;
			height: 46%;
			display: none;

			@include media-breakpoint-up(lg) {
				display: block;
			}
		}

		&__image {
			position: relative;
			z-index: 1;
			margin-top: 12px;

			@include media-breakpoint-up(lg) {
				margin-top: 0;
			}

			img {
				display: block;
				max-width: 100%;
			}

			&__gradient {
				@extend .gradient-fluid;
				height: 20vh;

				@include media-breakpoint-up(lg) {
					display: none;
				}
			}
		}

		&__text {
			position: relative;
			z-index: 3;
			@extend .container;

			@include media-breakpoint-up(lg) {
				width: 100%;
				margin: 45px 5% 0 auto;
				max-width: 480px;
				padding-left: 20px;
			}

			@include media-breakpoint-up(xxl) {
				margin-right: 10%;
			}

			.title {
				margin: 0 0 8px;
			}

			.subtitle {
				margin: 0;
				color: $purple500;
			}

			.button {
				margin: 24px 0 0;
			}
		}
	}

	&__bottom {
		position: relative;
		z-index: 2;
		background-color: var(--color-secondary);
		color: $white;
		padding: 55px 0 50px;

		@include media-breakpoint-up(md) {
			padding: 64px 0 74px;
		}

		&__button {
			position: absolute;
			top: 0;
			left: 0;
			right: 0;
			margin: 0 auto;
			transform: translateY(-50%);
		}

		.cosmetic-circles {
			position: absolute;
			top: -268px;
			right: -81px;
			z-index: 2;

			@include media-breakpoint-up(md) {
				top: -366px;
				right: -28px;
			}
		}

		&__content {
			position: relative;
			z-index: 3;
			p:first-child {
				margin-top: 0;
			}

			p:last-child {
				margin-bottom: 0;
			}
		}
	}
}
