.newsletter {
	background-color: var(--color-secondary);
	padding: 70px 0;
	color: $white;

	@include media-breakpoint-up(sm) {
		padding-bottom: 55px;
	}

	.title {
		margin-bottom: 48px;
	}

	.subtitle {
		margin-top: 0;
		margin-bottom: 24px;
	}
}
