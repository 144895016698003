.cards-flex {
	display: flex;
	flex-direction: column;
	flex-wrap: wrap;

	.card-plain {
		@include media-breakpoint-down(md) {
			margin: 0 auto 32px;
		}
	}

	@include media-breakpoint-up(md) {
		row-gap: 32px;
		column-gap: 32px;
		flex-direction: row;
		align-items: flex-start;
		justify-content: center;
		flex-wrap: nowrap;
	}
}

.card-plain {
	border-radius: 24px;
	width: 100%;
	max-width: 253px;
	margin: 0 auto;

	@include media-breakpoint-up(md) {
		width: 33.33%;
		margin: 0;
	}

	@include media-breakpoint-up(lg) {
		max-width: unset;
	}

	&__title {
		@extend .h3;
		margin: 0 0 12px;
	}

	&__text {
		p:first-child {
			margin-top: 0;
		}
	}

	&__icon {
		width: 80px;
		height: 80px;
		background-size: contain;
		background-position: center center;
		background-repeat: no-repeat;
		margin: 46px 0 0 auto;
	}

	&--purple {
		background-color: var(--color-secondary);
		color: $white;
		padding: 20px;
	}
}

.card-outline {
	padding: 24px;
	background-color: $white;
	border: 1px solid var(--color-secondary);
	border-radius: 24px;
	box-sizing: border-box;

	@include media-breakpoint-up(md) {
		padding: 32px 40px;
	}
}
