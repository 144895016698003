.site-footer {
	background-color: var(--color-secondary);
	color: $white;
	padding: 46px 0 40px;
	width: 100%;
	overflow-x: hidden;

	@include media-breakpoint-up(lg) {
		padding: 48px 0 25px;
	}

	&__top {
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		row-gap: 45px;

		@include media-breakpoint-up(md) {
			display: grid;
			grid-template-columns: 1fr 1fr;
			grid-template-rows: auto auto;
			grid-template-areas:
				"A A"
				"B C";
		}

		@include media-breakpoint-up(lg) {
			grid-template-areas: "A B C";
			grid-template-columns: 138px auto auto;
			grid-template-rows: 1fr;
			column-gap: 2%;
			row-gap: 0;
			margin-bottom: 20px;
		}

		@include media-breakpoint-up(xl) {
			column-gap: 43px;
			grid-template-columns: 152.51px auto 367px;
		}
	}

	&__logo {
		width: 139px;
		height: 139px;
		align-self: center;
		grid-area: A;

		@include media-breakpoint-up(md) {
			justify-self: center;
		}

		@include media-breakpoint-up(lg) {
			align-self: auto;
			justify-self: auto;
		}

		@include media-breakpoint-up(xl) {
			width: auto;
			height: auto;
		}

		svg {
			width: 100%;
			height: 100%;

			path {
				fill: #fff;
			}
		}
	}

	&__menu {
		@include reset-ul;
		grid-area: B;

		@include media-breakpoint-up(lg) {
			display: grid;
			grid-template-columns: repeat(2, auto);
			row-gap: 12px;
			column-gap: 8px;
		}

		@include media-breakpoint-up(xl) {
			column-gap: 20px;
			margin-right: 8%;
		}

		a {
			color: $white;
		}

		li {
			display: block;
			margin: 7.5px 0;

			@include media-breakpoint-up(lg) {
				margin: 0;
			}

			&:nth-child(1) {
				order: 1;
			}
			&:nth-child(2) {
				order: 3;
			}
			&:nth-child(3) {
				order: 2;
			}
			&:nth-child(4) {
				order: 4;
			}
			&:nth-child(5) {
				order: 5;
			}
		}

		a {
			display: inline-block;
			text-decoration: none;
			white-space: nowrap;

			&:after {
				margin-left: 16px;
				transform: translateY(6px);

				@include media-breakpoint-up(lg) {
					margin-left: 6px;
				}

				@include media-breakpoint-up(xl) {
					margin-left: 16px;
				}
			}

			&:not(.button-accessibility--white) {
				@extend .text-sm;
				display: block;

				@include media-breakpoint-up(lg) {
					font-weight: 400;
				}
			}
		}

		.button-accessibility--white {
			margin-top: 16px;

			@include media-breakpoint-up(lg) {
				margin-top: 8px;
			}
		}
	}

	&__newsletter {
		width: 100%;
		margin: 0 auto 16px;
		border-top: 1px solid $white;
		border-bottom: 1px solid $white;
		padding: 23px 0 46px;

		@include media-breakpoint-up(md) {
			padding: 0;
			border: 0;
			margin: 0;
		}

		.title {
			margin-top: 0;
			margin-bottom: 28px;
			text-align: left;

			@include media-breakpoint-up(sm) {
				text-align: center;
			}

			@include media-breakpoint-up(md) {
				margin-bottom: 18px;
			}
		}

		.newsletter-form {
			.field-row {
				align-items: flex-start;

				@include media-breakpoint-up(lg) {
					align-items: center;
				}
			}
		}
	}

	&__bottom {
		a {
			color: $white;
		}
	}
}

.menu-legals {
	@include reset-ul;
	display: flex;
	justify-content: center;
	flex-wrap: wrap;
	align-items: baseline;
	column-gap: 30px;

	@include media-breakpoint-up(lg) {
		justify-content: space-between;
	}

	a {
		@extend .text-link--xs;
	}
}
