.video {
	position: relative;
	width: 100%;
	max-width: 595px;
	aspect-ratio: 16/11;
	margin: 0 auto;
	display: flex;
	justify-content: center;
	align-items: center;

	&__thumbnail {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		z-index: 2;
		width: 100%;
		height: 100%;
		background-size: cover;
		background-position: center center;
		background-repeat: no-repeat;
		background-color: $grey300;
		filter: blur(4px);
	}

	&__play-button {
		display: block;
		width: 31px;
		height: 22px;
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		z-index: 3;
		border: 0;
		background-color: transparent;
		background-image: url($img-path + "icon-youtube.svg");
		background-size: contain;
		background-repeat: no-repeat;
		background-position: center center;
		cursor: pointer;

		@include media-breakpoint-up(sm) {
			width: 68px;
			height: 48px;
		}
	}
}
