.news {
	@extend .bg-concrete;
	position: relative;
	padding: 64px 0;
	overflow: hidden;
	box-shadow: inset 0 40px 60px -20px rgba(0, 0, 0, 0.15);

	@include media-breakpoint-up(md) {
		padding-bottom: 93px;
	}

	&:after {
		content: "";
		@extend .circles--colored;
		opacity: 0.25;
		position: absolute;
		bottom: -64px;
		left: -134px;
		transform: rotate(90deg);
		z-index: -1;
	}

	&__container {
		max-width: 1160px;
	}

	&__list {
		position: relative;

		&__slider {
			overflow: hidden;
			padding: 1px 3px;

			@include media-breakpoint-up(md) {
				padding: 1px 24px;
			}
		}

		.swiper-button {
			display: none;
			position: absolute;
			top: 50%;
			transform: translateY(-50%);
			z-index: 2;

			&-prev {
				left: -18px;
			}

			&-next {
				right: -18px;
			}

			@include media-breakpoint-up(md) {
				display: block;
			}
		}

		.swiper-pagination {
			margin-top: 35px;

			&-bullet {
				margin: 0 2px;
			}
		}

		&__card {
			@extend .card-outline;

			.title {
				margin: 12px 0 16px;

				@include media-breakpoint-up(md) {
					min-height: 78px;
				}
			}

			.text {
				color: $purple500;
				display: -webkit-box;
				-webkit-box-orient: vertical;
				-webkit-line-clamp: 5;
				overflow: hidden;

				@include media-breakpoint-up(md) {
					-webkit-line-clamp: 8;
				}

				p {
					margin: 0;
				}
			}

			.button-wrap {
				text-align: right;
				margin-top: 8px;

				@include media-breakpoint-up(sm) {
					margin-top: 16px;
				}
			}

			.button-learn-more {
				@extend .button-secondary;
				text-align: right;
			}
		}
	}
}
