.circles {
	display: block;
	width: 250.5px;
	height: 282.24px;
	background-size: contain;
	background-repeat: no-repeat;
	background-position: center center;
	pointer-events: none;

	@include media-breakpoint-up(md) {
		width: 501px;
		height: 564.47px;
	}

	&--colored {
		@extend .circles;
		background-image: url($img-path + "pattern-circles-01.svg");
	}

	&--white {
		@extend .circles;
		background-image: url($img-path + "pattern-circles-02.svg");
	}
}

.circle {
	display: block;
	width: 24px;
	height: 24px;
	background-size: contain;
	background-repeat: no-repeat;
	background-position: center center;
	pointer-events: none;

	@include media-breakpoint-up(md) {
		width: 50px;
		height: 50px;
	}

	@include media-breakpoint-up(lg) {
		width: 80px;
		height: 80px;
	}

	&--purple {
		@extend .circle;
		background-image: url($img-path + "circle-purple.svg");
	}
}

.gradient-fluid {
	position: absolute;
	height: 100%;
	width: 100%;
	top: 0;
	left: 0;
	z-index: 2;
	height: 46%;
	background: linear-gradient(180deg, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 1) 20%, rgba(255, 255, 255, 0) 100%);
	pointer-events: none;

	&::before,
	&::after {
		content: "";
		width: 100vw;
		height: 100%;
		background: inherit;
		position: absolute;
		top: 0;
	}
	&::before {
		left: 100%;
	}
	&::after {
		right: 100%;
	}
}
