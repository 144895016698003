.testimony {
	background-color: var(--color-primary);
	padding: 40px 0 33px;

	@include media-breakpoint-up(md) {
		padding: 50px 0;
	}

	&__container {
		max-width: 960px;
	}

	&__item {
		margin: 0;
		cursor: grab;
		opacity: 0;
		transition: opacity 0.25s;

		@include media-breakpoint-up(md) {
			display: grid;
			grid-template-columns: 200px auto;
			align-items: center;
			justify-content: flex-start;
			column-gap: 76px;
		}

		@include media-breakpoint-up(lg) {
			grid-template-columns: 336px auto;
		}

		&.swiper-slide-active {
			opacity: 1;
		}

		&:active {
			cursor: grabbing;
		}

		.image {
			position: relative;
			width: 100px;

			@include media-breakpoint-up(md) {
				width: auto;
			}

			&:before {
				content: "";
				@extend .circle--purple;
				position: absolute;
				top: 1.5%;
				left: 3.5%;
				z-index: -1;
			}
		}

		img {
			display: block;
			max-width: 100%;
			border-radius: 50%;
		}

		.text {
			margin-top: 12px;

			@include media-breakpoint-up(md) {
				max-width: 483px;
				margin: 0;
			}
		}

		.quote {
			@extend .h2;
			color: $white;
			margin: 0 0 14px;

			@include media-breakpoint-down(md) {
				font-size: 25px;
				line-height: 27.5px;
			}

			&:before {
				content: "“ ";
			}

			&:after {
				content: "”";
			}
		}

		.author {
			@extend .em;
			color: $purple500;

			@include media-breakpoint-up(md) {
				padding-bottom: 10%;
			}
		}

		.swiper-pagination {
			display: none;
			justify-content: flex-start;

			@include media-breakpoint-up(md) {
				display: flex;
			}
		}
	}

	.swiper-wrapper {
		margin-bottom: 12px;

		@include media-breakpoint-up(md) {
			margin-bottom: 0;
		}
	}
}
