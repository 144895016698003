// STATE CLASSES
.input--icon {
	padding-right: 48px;
	background-size: 16px;
	background-repeat: no-repeat;
	background-position: center right 16px;
}
.input--valid {
	@extend .input--icon;
	border-color: $green400;
	color: $green400;
	background-image: url($img-path + "icon-tick-green.svg");
}
.input--invalid {
	@extend .input--icon;
	border-color: $red100;
	color: $red500;
	background-image: url($img-path + "icon-warning-red.svg");
	&:hover {
		border-color: var(--color-primary);
	}
}

// BASE STYLE
input,
textarea {
	width: 100%;
	max-width: 327px;
	@extend .text-sm;
	font-weight: 400;
	color: $orange500;
	border: 1px solid $grey300;
	background-color: $white;
	border-radius: 9999px;
	padding: 11px 16px;

	&::placeholder {
		color: $grey300;
	}

	&.input-white {
		color: $white;
		border-color: $white;
		background-color: transparent;

		&::placeholder {
			color: $white;
		}
	}

	&,
	&.input-white {
		&:hover,
		&:focus {
			border-color: var(--color-primary);
		}
	}
}

input:not([type="submit"]) {
	&:focus {
		outline: 0;
		box-shadow: 0px 0px 0px 2px rgba(255, 149, 15, 0.25);
	}
}

[type="submit"] {
	@extend .button-primary;
}

.form-validation {
	input,
	textarea {
		&:not([value=""]) {
			&:not([type="submit"]) {
				&:not(:focus) {
					&:valid {
						@extend .input--valid;
					}
					&:invalid {
						@extend .input--invalid;
					}
				}
			}
		}
	}
}
