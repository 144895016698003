.map-section {
	width: 100%;
	overflow-x: hidden;
	padding: 64px 0 104px;
	position: relative;
	box-shadow: inset 0 -40px 60px -20px rgba(0, 0, 0, 0.15);

	.anchor {
		top: -35px;

		@include media-breakpoint-up(md) {
			top: -65px;
		}
	}

	.lien_entreprises {
		text-align: center;
		padding:30px 40px 0;
		@include media-breakpoint-up(md) {
			text-align: right;
		}
	}
}

.map {
	position: relative;
	width: 100%;
	max-width: 558px;
	margin: 0 auto;
	transform: translateX(-4%);

	@include media-breakpoint-up(md) {
		margin-top: 88px;
	}

	&__background {

		img{
			@include media-breakpoint-down(md) {
				width: 100%;
			}
		}
	}

	&-modal {
		width: 100%;
		max-width: 205px;
		height: 100%;
		max-height: 242px;
		padding: 24px 0 24px 24px;
		border-radius: 24px;
		border: 1px solid var(--color-primary);
		background-color: $white;
		pointer-events: all;
		position: absolute;
		top: 60%;
		right: 0;
		left: 0;
		margin: 0 auto;
		transform: translateY(-50%) translateX(200%);
		z-index: 1;
		visibility: hidden;
		transition: transform, visibility;
		transition-duration: 0.3s;
		transition-timing-function: ease-in-out;

		@include media-breakpoint-up(md) {
			top: 50%;
			left: unset;
			margin: 0;
		}

		&.is-active {
			transform: translateY(-50%) translateX(0);
			visibility: visible;

			@include media-breakpoint-up(md) {
				transform: translateY(-50%) translateX(calc(-100% + 10vw));
			}
		}

		&__content {
			overflow-x: hidden;
			overflow-y: auto;
			height: 100%;
			padding-right: 24px;
			// -ms-overflow-style: none; /* IE and Edge */
			// scrollbar-width: none; /* Firefox */

			// &::-webkit-scrollbar {
			// 	display: none;
			// }
		}

		&__title {
			display: block;
			@extend .h4;
			@extend .text-purple;
			text-transform: uppercase;
			margin-top: 16px;

			&:first-child {
				margin-top: 0;
			}
		}

		&__subtitle {
			display: block;
			@extend .h5;
			color: $purple500;
		}

		&__button-close {
			@extend .button-close;
			width: 20px;
			height: 20px;
			background-color: $white;
			border-radius: 50%;
			position: absolute;
			top: -4px;
			right: -3px;
			z-index: 1;

			&:hover {
				opacity: 1 !important;
			}
		}
	}

	&__pin {
		font-size: 16px;
		font-weight: 700;
		line-height: 19px;
		color: $purple300;
		padding: 0;
		width: 24px;
		height: 24px;
		background-color: $white;
		border: 3px solid var(--color-primary);
		z-index: 2;
		cursor: pointer;
		transform: translate(-50%, -50%);
		transform-origin: 0 0;
		transition: transform 0.3s;

		&,
		&:after {
			position: absolute;
			display: block;
			border-radius: 50%;
		}

		&:after {
			content: "";
			width: 50%;
			height: 50%;
			background-color: var(--color-primary);
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%) scale(0);
			transition: transform 0.1s;
		}

		&:hover,
		&.is-active {
			transform: scale(1.66) translate(-50%, -50%);
		}

		&.is-active {
			z-index: 3;
			box-shadow: 0 0 4px rgba(0, 0, 0, 0.15);

			&:after {
				// transform: translate(-50%, -50%) scale(1);
			}
		}
	}

	&__modals {
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		z-index: 3;
		pointer-events: none;
	}
}
