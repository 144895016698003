.button-primary {
	display: inline-block;
	width: auto;
	@extend .text-sm;
	padding: 0.8em 2.4em;
	border-radius: 999px;
	color: $white;
	background-color: var(--color-primary);
	border: 0;
	cursor: pointer;
	text-decoration: none;
	transition-property: color, background-color, background-image, box-shadow;
	transition-duration: 200ms;
	transition-timing-function: linear;

	&:hover:not(:disabled) {
		color: $orange500;
		background-color: $orange100;
		box-shadow: 0 12px 35px 1px rgba(59, 59, 59, 0.15);
	}

	&:active {
		color: $white;
		background-color: $orange500;
	}

	&:disabled {
		background-color: #f0f0f0;
		color: $grey300;
		border: 0;
	}

	&--arrow {
		@extend .button-primary;
		padding-right: 4em;
		padding-left: 2.6em;
		background-image: url($img-path + "icon-arrow-right--white.svg");
		background-size: 11px;
		background-repeat: no-repeat;
		background-position: center right 2.53em;

		&:hover {
			background-image: url($img-path + "icon-arrow-right--orange500.svg");
		}
	}
}

.button-secondary {
	display: inline-block;
	position: relative;
	@extend .text-sm;
	padding: 15px 39px 15px 0;
	color: var(--color-primary);
	background-color: transparent;
	border: 0;
	text-decoration: none;
	cursor: pointer;
	transition-property: color;

	&,
	&:after {
		transition-duration: 100ms;
		transition-timing-function: linear;
	}

	&:after {
		content: "";
		display: block;
		width: 23px;
		height: 23px;
		border-radius: 50%;
		background-color: var(--color-primary);
		background-image: url($img-path + "icon-arrow-right--white.svg");
		background-size: 12.67px 12.32px;
		background-repeat: no-repeat;
		background-position: center center;
		position: absolute;
		top: 50%;
		right: 0;
		transform: translateY(-50%);
		z-index: 1;
		transition-property: background-color;
	}

	&:hover {
		color: $orange500;

		&:after {
			background-color: $orange500;
		}
	}

	&:active {
		color: $purple500;

		&:after {
			background-color: $purple500;
		}
	}

	&:disabled {
		color: $grey300;

		&:after {
			background-color: $grey300;
		}
	}
}

.button-arrow-down {
	display: block;
	width: 40px;
	height: 40px;
	border-radius: 50%;
	background-color: var(--color-primary);
	background-image: url($img-path + "icon-arrow-down.svg");
	background-repeat: no-repeat;
	background-position: center center;
	background-size: 16px;
	border: 0;
	cursor: pointer;

	&:hover,
	&:active {
		background-color: lighten($orange300, 7%);
	}
}

.button-linkedin {
	display: block;
	width: 24px;
	height: 24px;
	border: 0;
	border-radius: 50%;
	background-image: url($img-path + "icon-linkedin--white.svg");
	background-repeat: no-repeat;
	background-position: center center;
	background-size: 56%;

	&--color {
		@extend .button-linkedin;
		background-color: var(--color-primary);
	}
}

.button-accessibility {
	display: inline-block;
	font-family: $font__alt;
	font-size: 12px;
	line-height: 1.2em;
	font-weight: 700;
	text-decoration: none;
	text-transform: uppercase;
	padding: 9px 42px 9px 8px;
	border: 1px solid transparent;
	border-radius: 999px;
	background-image: url($img-path + "icon-accessibility--black.svg");
	background-size: 18.75px 16px;
	background-repeat: no-repeat;
	background-position: center right 8px;
	cursor: pointer;

	&--color {
		@extend .button-accessibility;
		color: $black;
		border-color: var(--color-primary);
	}

	&--white {
		@extend .button-accessibility;
		background-image: url($img-path + "icon-accessibility--white.svg");
		color: $white;
		border-color: $white;
	}
}

.button-close {
	display: block;
	width: 33px;
	height: 33px;
	background-image: url($img-path + "icon-close-orange.svg");
	background-size: contain;
	background-repeat: no-repeat;
	background-position: center center;
	background-color: transparent;
	border: 0;
	cursor: pointer;

	&:hover:not(:active) {
		opacity: 0.85;
	}
}

.button-menu {
	display: block;
	width: 18px;
	height: 16px;
	background-image: url($img-path + "icon-burger-menu.svg");
	background-repeat: no-repeat;
	background-position: center center;
	background-size: contain;
	background-color: transparent;
	border: 0;
	cursor: pointer;
	user-select: none;

	&--close {
		@extend .button-menu;
		background-image: url($img-path + "icon-black-cross.svg");
	}
}
