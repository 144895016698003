.newsletter-form {
	.field-row {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		row-gap: 12px;

		@include media-breakpoint-up(sm) {
			flex-direction: row;
			height: 41px;
		}
	}

	.submit-button {
		margin-bottom: 28px;

		@include media-breakpoint-up(sm) {
			margin-left: -92px;
			margin-bottom: 0;
		}
	}

	[type="submit"] {
		@extend .button-primary--arrow;
	}

	[type="email"] {
		@include media-breakpoint-up(sm) {
			padding-right: 96px;
		}
	}

	[type="email"],
	[type="submit"] {
		@include media-breakpoint-up(sm) {
			height: 100%;
		}
	}

	.cgu-input {
		@include media-breakpoint-up(sm) {
			margin-top: 13px;
		}
	}
}

.label-form {
	display: flex;
	flex-direction: column;
	align-items: center;
	row-gap: 4px;
	max-width: 370px;
	margin: 0 auto;

	.checkbox {
		margin: 12px 0;

		&__text {
			color: $grey500;
		}
	}
}

.cgu-input {
	max-width: 365px;
	display: grid;
	grid-template-columns: 16px auto;
	column-gap: 8px;
	cursor: pointer;

	@include media-breakpoint-up(sm) {
		grid-template-columns: 12px auto;
		column-gap: 6px;
	}

	.fake-checkbox {
		position: relative;
		display: block;
		width: 16px;
		height: 16px;
		background-color: $white;
		border: 1px solid var(--color-primary);
		border-radius: 50%;

		@include media-breakpoint-up(sm) {
			margin-top: 2px;
			width: 12px;
			height: 12px;
		}
	}

	input[type="checkbox"]:checked {
		& + .fake-checkbox {
			background-color: var(--color-primary);
		}
	}

	input[type="checkbox"]:focus {
		& + .fake-checkbox {
			box-shadow: 0px 0px 0px 2px rgba(255, 149, 15, 0.25);
		}
	}
}
