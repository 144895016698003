.objectifs {
	@extend .bg-concrete;
	padding: 70px 0 0;
	box-shadow: inset 0 0 40px rgba(0, 0, 0, 0.15);
	overflow: hidden;
	position: relative;

	@include media-breakpoint-up(lg) {
		padding: 90px 0 80px;
	}

	&:before {
		content: "";
		@extend .circles--colored;
		position: absolute;
		top: -38px;
		left: -310px;
		z-index: -1;
		opacity: 0.25;
		transform: rotate(90deg) scale(-1, 1);

		@include media-breakpoint-up(lg) {
			top: -60px;
			left: -335px;
		}

		@include media-breakpoint-down(lg) {
			height: 375px;
			width: 423px;
		}
	}

	.title-circle--purple {
		@extend .h1;
	}

	&__content {
		position: relative;
		display: flex;
		flex-direction: column-reverse;

		@include media-breakpoint-up(lg) {
			display: block;
			padding: 0 0 0 10.5%;
		}

		&:before {
			content: "";
			display: none;
			width: 473px;
			height: 473px;
			background-image: url($img-path + "circle-grey.svg");
			background-size: contain;
			background-repeat: no-repeat;
			background-position: center center;
			position: absolute;
			bottom: -180px;
			right: -240px;
			z-index: -1;

			@include media-breakpoint-up(lg) {
				display: block;
			}
		}

		.text {
			@extend .container;
			margin-bottom: 24%;

			@include media-breakpoint-up(sm) {
				margin-bottom: 80px;
			}

			@include media-breakpoint-up(lg) {
				max-width: unset;
				width: 100%;
				margin-bottom: 0;
			}
		}

		.paragraph {
			margin-bottom: 32px;
			max-width: 787px;

			&:last-child {
				margin-bottom: 0;
			}
		}

		.title {
			margin: 0 0 11px;
			color: $purple300;
		}

		p {
			margin: 0;
		}

		img {
			display: block;
			margin: 0 auto;
			height: 117vw;
			width: 100%;
			object-fit: cover;
			object-position: center center;

			@include media-breakpoint-up(sm) {
				height: auto;
				width: 575px;
				max-width: 100%;
				// object-fit: none;
				// object-position: none;
			}

			@include media-breakpoint-up(lg) {
				float: right;
				margin-left: 77px;
				padding-bottom: 36px;
			}
		}

		&:after {
			content: "";
			clear: both;
		}
	}
}
