.icon-linkedin--white,
.icon-email--white,
.icon-arrow--white {
	&:after {
		content: "";
		display: inline-block;
		width: 23px;
		height: 23px;
		border-radius: 50%;
		background-color: $white;
		background-size: 12.6px;
		background-repeat: no-repeat;
		background-position: center center;
	}
}
.icon-linkedin--white {
	&:after {
		background-image: url($img-path + "icon-linkedin--purple.svg");
		background-position: top 4.5px center;
	}
}

.icon-email--white {
	&:after {
		background-image: url($img-path + "icon-mail--purple.svg");
		background-position: top 6px center;
	}
}

.icon-arrow--white {
	&:after {
		background-image: url($img-path + "icon-arrow-right--purple.svg");
	}
}

.icon-valid-purple-big {
	width: 145px;
	height: 145px;
	background-image: url($img-path + "icon-valid-purple-big.svg");
	background-size: contain;
	background-repeat: no-repeat;
	background-position: center center;
}
