.anchor {
	display: block;
	width: 0;
	height: 0;
	position: absolute;
	top: -20px;
	left: 0;

	@include media-breakpoint-up(md) {
		top: -40px;
	}
}
