$orange100: #ffecd5;
$orange300: #ff950f;
$orange400: #ad6200; // primary accessible color
$orange500: #7c4106;

$purple100: #e7e6f3;
$purple300: #5549d1;
$purple500: #1a1734;

$green400: #165c45;

$red100: #f3e9ea;
$red500: #d02e3b;

$grey100: #e4e4e4;
$grey300: #a7a7a7;
$grey500: #595959;

$white: #ffffff;
$black: #000000; // secondary accessible color

:root {
	--color-primary: #{$orange300};
	--color-secondary: #{$purple300};

	.accessibility-enabled {
		--color-primary: #{$orange400};
		--color-secondary: #{$black};
	}
}
