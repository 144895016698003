@import "variables/variables";
@import "mixins/mixins";

/*--------------------------------------------------------------
# Generic
--------------------------------------------------------------*/

/* Normalize + Box sizing
--------------------------------------------- */
@import "generic/normalize";
@import "generic/box-sizing";

// -- Paths
$img-path: "/src/images/";
