.avantages {
	position: relative;
	padding: 65px 0 80px;

	@include media-breakpoint-up(lg) {
		padding: 77px 0 58px;
	}

	.title-circle--purple {
		@extend .h1;
	}

	&__item {
		&:nth-child(1) {
			.card-plain__icon {
				background-image: url($img-path + "icon-colors-leaflet.svg");
			}
		}
		&:nth-child(2) {
			.card-plain__icon {
				background-image: url($img-path + "icon-certified.svg");
			}
		}
		&:nth-child(3) {
			.card-plain__icon {
				background-image: url($img-path + "icon-progressive.svg");
			}
		}
	}
}
