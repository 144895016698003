body {
	font-family: $font__main;
	font-size: 17px;
	font-weight: 400;
	line-height: 21.25px;
	color: $black;

	@include media-breakpoint-up(lg) {
		font-size: 18px;
		line-height: 23.4px;
	}
}

h1,
.h1,
h2,
.h2,
h3,
.h3,
h4,
.h4,
h5,
.h5 {
	font-family: $font__alt;
	font-weight: 700;
}

h1,
.h1 {
	font-size: 34px;
	line-height: 34.4px;
	letter-spacing: 0.01em;

	@include media-breakpoint-up(lg) {
		font-size: 42px;
		line-height: 50.4px;
	}
}

h2,
.h2 {
	font-size: 28px;
	line-height: 30.8px;

	@include media-breakpoint-up(lg) {
		font-size: 32px;
		line-height: 38.4px;
	}
}

h3,
.h3 {
	font-size: 22px;
	line-height: 24.2px;
	letter-spacing: -0.01em;

	@include media-breakpoint-up(lg) {
		font-size: 24px;
		line-height: 26px;
	}
}

h4,
.h4 {
	font-size: 18px;
	line-height: 21.6px;
}

h5,
.h5 {
	font-size: 12px;
	line-height: 14.4px;
	letter-spacing: 0.03em;
	text-transform: uppercase;
}

.title-circle {
	@extend .h2;
	display: block;
	position: relative;
	z-index: 2;
	margin: 0 0 54px;
	text-align: center;

	@include media-breakpoint-up(lg) {
		margin-bottom: 64px;
	}

	&:after {
		content: "";
		display: block;
		width: 78px;
		height: 78px;
		border-radius: 50%;
		position: absolute;
		top: 50%;
		left: 0;
		right: 0;
		margin: 0 auto;
		transform: translateY(-45%);
		z-index: -1;
	}

	&--purple {
		@extend .title-circle;
		@extend .text-purple;

		&:after {
			background-color: $orange100;
		}
	}

	&--white {
		@extend .title-circle;
		color: $white;

		&:after {
			background-color: $purple500;
			opacity: 0.25;
		}
	}
}

em,
.em {
	font-size: 21px;
	line-height: 24.38px;
	font-weight: 700;

	@include media-breakpoint-up(lg) {
		font-size: 23px;
		line-height: 26.71px;
	}
}

strong,
.fw-b {
	font-weight: 700;
}

.ta-c {
	text-align: center;
}

.text-sm {
	font-size: 15px;
	line-height: 1.161em;
	letter-spacing: -0.03em;
	font-weight: 700;
}

.text-xs {
	font-size: 12px;
	line-height: 14px;
}

.text-link {
	text-decoration: underline;

	&--xs {
		@extend .text-link;
		@extend .text-xs;
	}
}

.text-purple {
	color: var(--color-secondary);
}

.text-orange {
	color: var(--color-primary);
}

.no-decoration-black {
	color: $black !important;
	text-decoration: underline;
}