html {
	scroll-behavior: smooth;
}

body {
	background-color: $white;
	overflow-x: hidden;

	&.js-no-transitions * {
		transition: none !important;
	}
}

.container {
	width: 90%;
	margin-left: auto;
	margin-right: auto;
	max-width: 1200px;

	&-fluid {
		@extend .container;
		width: 100%;
		max-width: 1270px;
	}

	&-md {
		@extend .container;
		max-width: 822px;
	}

	&-xs {
		@extend .container;
		max-width: 380px;
	}
}

.bg-concrete {
	background-image: url($img-path + "bg-concrete-pattern.png");
	background-size: 520px;
	background-position: top left;
	background-repeat: repeat;
}

.mobile-only {
	@include media-breakpoint-up(md) {
		display: none !important;
	}
}
