.video-section {
	@extend .bg-concrete;
	overflow: hidden;
	box-shadow: inset -0 -20px 40px rgba(0, 0, 0, 0.15);

	&__container {
		position: relative;
		padding: 58px 0 52px;

		@include media-breakpoint-up(md) {
			padding: 90px 0 112px;
		}

		&::after {
			content: "";
			@extend .circles--colored;
			position: absolute;
			bottom: -130px;
			right: -162px;
			z-index: -1;
			opacity: 0.25;
			transform: rotate(90deg);

			@include media-breakpoint-down(md) {
				width: 375px;
				height: 423px;
				bottom: -76px;
				right: -240px;
			}
		}
	}
}
