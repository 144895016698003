.map-modal{


    @include media-breakpoint-up(lg) {
        right: calc(((100vw - 560px) / 2) - 269px);
        transform: translateY(-50%);
        transition: none;
    
        &.is-active{
            transform: translateY(-50%);
        }
	}

}